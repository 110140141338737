import { createMuiTheme } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import shadows from "./shadows";

export default createMuiTheme({
    palette: {
        primary: {
            main: "#6750a4",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#5e5c71",
            contrastText: "#ffffff",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
        text: {
            primary: "#1c1b1f",
        },
    },
    typography: {
        fontFamily: ["Plus Jakarta Sans, sans-serif", "Arial"].join(","),
        button: {
            textTransform: "capitalize",
        },
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "#e4e1ecaa",
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: shadows.xl,
                minWidth: "20vw",
            },
        },
        MuiMenu: {
            paper: {
                boxShadow: shadows.lg,
                background: "#FFFFFF",
            },
        },
    },
});

export const appbarTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#ffffff",
            contrastText: "#6750a4",
        },
        secondary: {
            main: "#6750a4",
            contrastText: "#FFFFFF",
        },
        action: {
            hover: "#e2dfff",
            active: "#ffffff",
            focus: "#ffffff",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["Plus Jakarta Sans, sans-serif", "Arial"].join(","),
        button: {
            textTransform: "capitalize",
        },
    },
});

export const invertedTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#FFFFFF",
            contrastText: "#6750a4",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["Plus Jakarta Sans, sans-serif", "Arial"].join(","),
        button: {
            textTransform: "capitalize",
        },
    },
});

export const errorTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#bb1614",
            contrastText: "#FFFFFF",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["Plus Jakarta Sans, sans-serif", "Arial"].join(","),
        button: {
            textTransform: "capitalize",
        },
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "#e4e1ecaa",
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: shadows.xl,
                minWidth: "20vw",
            },
        },
        MuiMenu: {
            paper: {
                boxShadow: shadows.lg,
                background: "#FFFFFF",
            },
        },
    },
});

export const successTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#006e1c",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: deepOrange[500],
            contrastText: "#FFFFFF",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["Plus Jakarta Sans, sans-serif", "Arial"].join(","),
        button: {
            textTransform: "capitalize",
        },
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "#e4e1ecaa",
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: shadows.xl,
                minWidth: "20vw",
            },
        },
        MuiMenu: {
            paper: {
                boxShadow: shadows.lg,
                background: "#FFFFFF",
            },
        },
    },
});

export { shadows };
