import { useTheme } from "@material-ui/core";
import React from "react";
import { ScaleLoader } from "react-spinners";

export default function CustomSpinner({ dense }) {
    const theme = useTheme();

    // Revisar la sesion, validar y actualizar permisos

    return (
        <div
            style={{
                marginTop: theme.spacing(dense ? 2 : 6),
                marginBottom: theme.spacing(dense ? 2 : 6),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.primary.main,
            }}
        >
            <ScaleLoader color={theme.palette.primary.main} />
        </div>
    );
}
