import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        fechaInicio: null,
        fechaFin: null,
        sucursal: "",
    },
};

const slice = createSlice({
    name: "charts",
    initialState,
    reducers: {
        unsetChartQuery: (state) => {
            state.value = initialState.value;
        },
        setChartQuery: (state, action) => {
            const { fechaInicial, fechaFinal, sucursal } = action.payload;
            const fechaInicio = {
                day: fechaInicial.getDate(),
                month: fechaInicial.getMonth() + 1,
                year: fechaInicial.getFullYear(),
            };
            const fechaFin = {
                day: fechaFinal.getDate(),
                month: fechaFinal.getMonth() + 1,
                year: fechaFinal.getFullYear(),
            };
            state.value = { fechaInicio, fechaFin, sucursal };
        },
    },
});

export const selectIsChartQueryReady = ({ charts }) =>
    charts.value.fechaFin !== null &&
    charts.value.fechaInicio !== null &&
    charts.value.sucursal !== "";
export const selectChartQuery = ({ charts }) => charts.value;

export const { setChartQuery, unsetChartQuery } = slice.actions;
export default slice.reducer;
