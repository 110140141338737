import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SESS_COOKIE } from "../config";
import {
    selectIsLogged,
    setSession,
    unsetSession,
} from "../store/reducers/session";
import { postRequest } from "../requests";
import axios from "axios";

export default function Root() {
    const [cookies, setCookie] = useCookies([SESS_COOKIE]);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLogged = useSelector(selectIsLogged);

    useEffect(() => {
        (async () => {
            let session = cookies[SESS_COOKIE];
            if (typeof session === "string") {
                try {
                    session = JSON.parse(session);
                } catch (e) {
                    session = null;
                }
            }
            axios.defaults.headers["Sv-Authorization"] = session
                ? session.token
                : "";

            if (!session) {
                dispatch(unsetSession());
                if (pathname.indexOf("login") === -1) {
                    navigate("/login");
                }
                return;
            }

            if (!isLogged) {
                try {
                    const r = await postRequest({
                        body: {},
                        route: "/api/user/checkToken",
                    });

                    session.rol = r.data.data.rol;
                    setCookie(SESS_COOKIE, session, {
                        path: '/'
                    });
                    dispatch(setSession(session));
                    return;
                } catch (e) {
                    setCookie(SESS_COOKIE, null, {
                        path: "/",
                    });
                    if (pathname.indexOf("login") === -1) {
                        navigate("/login");
                    }
                    return;
                }
            }

            if (pathname.indexOf("login") !== -1 || pathname === "/") {
                navigate("/admin");
            }

            // Validaciones según el rol del usuario
        })();
    }, [cookies, isLogged]);

    return <Outlet />;
}
