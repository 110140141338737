import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import CustomSpinner from "./components/utils/customSpinner";

import Root from "./routes/root";
import Splash from "./routes/splash";
const Agentes = lazy(() => import("./routes/agentes"));
const Departamentos = lazy(() => import("./routes/departamentos"));
const Home = lazy(() => import("./routes/home"));
const Registros = lazy(() => import("./routes/registros"));
const Sucursales = lazy(() => import("./routes/sucursales"));
const Admin = lazy(() => import("./routes/admin"));
const Carriers = lazy(() => import("./routes/carriers"));
const Extractores = lazy(() => import("./routes/extractores"));
const Login = lazy(() => import("./routes/login"));
const Solicitudes = lazy(() => import("./routes/solicitudes"));
const Troncales = lazy(() => import("./routes/troncales"));
const Usuarios = lazy(() => import("./routes/usuarios"));

function App() {
    return (
        <Routes>
            <Route path="/" element={<Root />}>
                <Route index element={<Splash />} />
                <Route
                    path="login"
                    element={
                        <Suspense fallback={<CustomSpinner />}>
                            <Login />
                        </Suspense>
                    }
                />
                <Route
                    path="admin"
                    element={
                        <Suspense fallback={<CustomSpinner />}>
                            <Admin />
                        </Suspense>
                    }
                >
                    <Route
                        index
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Home />
                            </Suspense>
                        }
                    />
                    <Route
                        path="cdr"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Registros />
                            </Suspense>
                        }
                    />
                    <Route
                        path="agentes"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Agentes />
                            </Suspense>
                        }
                    />
                    <Route
                        path="sucursales"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Sucursales />
                            </Suspense>
                        }
                    />
                    <Route
                        path="departamentos"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Departamentos />
                            </Suspense>
                        }
                    />
                    <Route
                        path="carriers"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Carriers />
                            </Suspense>
                        }
                    />
                    <Route
                        path="troncales"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Troncales />
                            </Suspense>
                        }
                    />
                    <Route
                        path="reportes"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Solicitudes />
                            </Suspense>
                        }
                    />
                    <Route
                        path="extractores"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Extractores />
                            </Suspense>
                        }
                    />
                    <Route
                        path="usuarios"
                        element={
                            <Suspense fallback={<CustomSpinner />}>
                                <Usuarios />
                            </Suspense>
                        }
                    />
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
