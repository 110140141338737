import React from "react";
import CustomSpinner from "../components/utils/customSpinner";

export default function Splash() {
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CustomSpinner />
        </div>
    );
}
