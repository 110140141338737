import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        rol: "",
        token: "",
    },
};

const slice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setSession: (state, action) => {
            state.value = action.payload;
        },
        unsetSession: (state) => {
            state.value = initialState.value;
        },
    },
});

export const selectIsLogged = (state) => state.session.value.token !== "";
export const selectIsAdmin = (state) => state.session.value.rol === "admin";
export const selectIsAgent = (state) => state.session.value.rol === "agent";
export const selectToken = (state) => state.session.value.token;
export const { setSession, unsetSession } = slice.actions;
export default slice.reducer;
