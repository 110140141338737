import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import theme from "../../theme";
import store from "../../store";

export default function Base({ children }) {
    return (
        <ReduxProvider store={store}>
            <CookiesProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>{children}</Router>
                </ThemeProvider>
            </CookiesProvider>
        </ReduxProvider>
    );
}
