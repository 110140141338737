import { configureStore } from "@reduxjs/toolkit";
import session from "./reducers/session";
import charts from "./reducers/charts";

export default configureStore({
    reducer: {
        session,
        charts,
    },
});
