import axios from "axios";
import React from "react";
import { Cookies } from "react-cookie";
import ReactDOM from "react-dom";
import App from "./App";
import Base from "./components/utils/base";
import { SESS_COOKIE } from "./config";

const cookies = new Cookies();
let session = cookies.get(SESS_COOKIE);
if (typeof session === "string") {
    try {
        session = JSON.parse(session);
    } catch (e) {
        session = null;
    }
}
axios.defaults.headers["Sv-Authorization"] = session ? session.token : "";

ReactDOM.render(
    <Base>
        <App />
    </Base>,
    document.getElementById("root")
);
