const config = {
    menu: [
        {
            group: "Reportes",
            routes: [
                { to: "/admin", label: "Dashboard" },
                {
                    to: "/admin/reportes",
                    label: "Reportes",
                },
            ],
        },
        {
            group: "CDR",
            routes: [
                { to: "/admin/cdr", label: "Registros" },
                { to: "/admin/extractores", label: "Extractores" },
            ],
        },
        {
            group: "Telefonía",
            routes: [
                { to: "/admin/carriers", label: "Carriers" },
                { to: "/admin/troncales", label: "Troncales" },
            ],
        },
        {
            group: "Información",
            routes: [
                { to: "/admin/usuarios", label: "Usuarios" },
                { to: "/admin/agentes", label: "Agentes" },
                { to: "/admin/sucursales", label: "Sucursales" },
                { to: "/admin/departamentos", label: "Departamentos" },
            ],
        },
    ],
    headers: {
        registros: [
            { key: "fecha", title: "Fecha" },
            { key: "segundosDuracion", title: "Duración" },
            { key: "extension", title: "Extensión" },
            { key: "troncal", title: "Troncal" },
            { key: "codigoDeAutorizacion", title: "Código de autorización" },
            { key: "numeroMarcado", title: "Número marcado" },
            { key: "tipoTelefono", title: "Tipo teléfono" },
            { key: "entrante", title: "Entrante/Saliente" },
            { key: "office.nombre", title: "Sucursal" },
        ],
        departamentos: [
            { key: "_id", title: "ID" },
            { key: "codigo", title: "Codigo" },
            { key: "nombre", title: "Nombre" },
            { key: "office.nombre", title: "Sucursal" },
        ],
        agentes: [
            { key: "userID", title: "ID" },
            { key: "nombre", title: "Nombre" },
            { key: "folioN", title: "Folio N" },
            { key: "codigoV", title: "Codigo V" },
            { key: "codigoN", title: "Codigo N" },
            { key: "extensiones", title: "Extensiones" },
            { key: "determinante", title: "Determinante" },
            { key: "departamento.office.nombre", title: "Sucursal" },
            { key: "departamento.nombre", title: "Departamento" },
            { key: "fechaAltaTabla", title: "Fecha Alta" },
            { key: "fechaBajaTabla", title: "Fecha Baja" },
        ],
        sucursales: [
            { key: "_id", title: "ID" },
            { key: "nombre", title: "Nombre" },
            { key: "rangosExtensiones", title: "Rangos de extensiones" },
        ],
        carriers: [
            { key: "_id", title: "ID" },
            { key: "Nombre", title: "Nombre" },
        ],
        troncales: [
            { key: "codigo", title: "Código" },
            { key: "carrier.Nombre", title: "Carrier" },
            { key: "descripcion", title: "Descripción" },
        ],
        solicitudes: [
            { key: "nombreDeReporte", title: "Nombre" },
            // { key: 'tipoReporte', title: 'Tipo del reporte' },
            { key: "progreso", title: "Procesado" },
            { key: "fechaInicio", title: "Fecha de Inicio" },
            { key: "fechaTermino", title: "Fecha de Termino" },
        ],
        extractores: [
            { key: "nombre", title: "Nombre" },
            { key: "ruta", title: "Ruta" },
            { key: "patron", title: "Patrón" },
            { key: "ultimaLineaLeida", title: "Última Línea Leída" },
            { key: "ultimoDiaLeido", title: "Último Día Leído" },
        ],
    },
    routes: {
        "/admin/cdr": "Registros",
        "/admin/agentes": "Agentes",
        "/admin/sucursales": "Sucursales",
        "/admin/departamentos": "Departamentos",
        "/admin/carriers": "Carriers",
        "/admin/troncales": "Troncales",
        "/admin/reportes": "Reportes",
        "/admin/extractores": "Extractores",
        "/admin/usuarios": "Usuarios",
    },
};

export const SESS_COOKIE = "SV_SESS_WALMART";
export default config;
