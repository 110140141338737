import axios from "axios";
import { Cookies } from "react-cookie";
import { SESS_COOKIE } from "./config";
const cookies = new Cookies();

export async function uploadFile({ file, field, route, body }) {
    const fd = new FormData();
    fd.append(field, file);

    if (body) {
        Object.keys(body).forEach((k) => {
            fd.append(k, body[k]);
        });
    }

    const r = await axios.post(route, fd).catch((e) => {
        if (e.response) {
            if (e.response.status === 403) {
                cookies.set(SESS_COOKIE, null);
            }

            return {
                data: {
                    Estado: "error",
                    Descripcion: e.response.data,
                },
            };
        }
        throw e;
    });

    const d = r.data;

    if (d.Estado === "ok") {
        return {
            title: "Éxito",
            content: "Archivo cargado exitosamente",
        };
    } else {
        throw new Error(d.Descripcion);
    }
}

export async function postRequest({ body, route, content }) {
    const r = await axios.post(route, body).catch((e) => {
        if (e.response) {
            if (e.response.status === 403) {
                cookies.set(SESS_COOKIE, null);
            }

            return {
                data: {
                    Estado: "error",
                    Descripcion: e.response.data,
                },
            };
        }
        throw e;
    });
    const d = r.data;

    if (d.Estado === "ok") {
        return {
            title: "Éxito",
            content,
            data: d,
        };
    } else {
        throw new Error(d.Descripcion);
    }
}

export async function listRequest({ self, route, mask, body, query }) {
    try {
        const { page } = self.state;

        const r = await axios
            .post(route + "/" + page, { ...body })
            .catch((e) => {
                if (e.response) {
                    if (e.response.status === 403) {
                        cookies.set(SESS_COOKIE, null);
                        window.location.reload();
                    }

                    return {
                        data: {
                            Estado: "error",
                            Descripcion: e.response.data,
                        },
                    };
                }
                throw e;
            });
        const d = r.data;

        if (d.Estado === "ok") {
            self.setState({
                items: d.Registros.map(
                    mask
                        ? mask
                        : (v) => ({
                              ...v,
                          })
                ),
                count: d.Cant ? d.Cant : 0,
            });
        } else {
            throw new Error(r.Descripcion);
        }
    } catch (e) {
        self.setState({
            alert: {
                title: "Error",
                content: e.message,
                error: true,
            },
        });
    }
}
